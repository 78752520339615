.portal {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;

  .loading-content {
    text-align: center;
    background-color: #fff;
    padding: 50px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;

    .top-image {
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      width: 20%;
      margin-top: 60px;
    }

    p {
      font-weight: 500;
    }
  }
}