.main-container {
  padding: 24px;
}

.nav-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-weight: bolder;
  background-color: #fff;
  border-bottom: 1px solid #dcdce4;

  >div {
    align-items: center;
    display: flex;
    min-height: 50px;
    margin: 0.25em 0.5em;
  }
}

.hint-text {
  color: var(--company-identity-color);
  padding: 20px;
  font-size: 20px;
  width: 100%;
  text-align: center;
}
