.notFound {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.notFound__banner {
  background-color: var(--company-identity-color);
  text-align: center;
  padding: 1rem;
}

.notFound__main {
  margin: auto;
  font-size: 2rem;
  color: rgb(183, 183, 183);
}