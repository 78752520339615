.portal-footer {
  background-color: #333333;
  text-align: center;
  width: 100%;
  color: #d3d3d3;
  font-size: .7rem;
  padding: 1em 0;
  box-sizing: border-box;
}

.portal-footer__title {
  font-weight: bold;
}

.portal-footer__description {
  width: 70%;
  margin: auto;
  margin-top: .5em;
  margin-bottom: 2em;
}

.portal-footer__link {
  color: #b3b1a8;
  text-decoration: none;
}

.portal-footer__link:hover {
  text-decoration: underline;
}


.portal-footer__link::after {
  content: '|';
  margin: 0 .25em;
}

.portal-footer__link:last-of-type::after {
  display: none;
}