.timeout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  background-color: rgba(168, 58, 212, 0.23);

  .timeout-content {
    text-align: center;
    background-color: #fff;
    padding: 50px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .top-image {
      width: 100%;
      margin-top: 60px;
    }

    h1 {
      font-size: 24px;
      color: #333;
    }

    p {
      color: #666;
    }
  }
}