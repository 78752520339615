.filter-box {
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 51px;
  border-radius: 8px;
  border: 1px solid var(--company-identity-color);
  padding: 0px 16px;
  width: 100%;
  max-width: 350px;

  .filter-icon {
    height: 1.4em;
    width: 1.4em;
    filter: var(--svg-filter);
  }

  .filter-value-field {
    width: 100%;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    border: 0;
  }
}
