.tags-container {
  display: flex;
  gap: 4px;

  .tag {
    display: flex;
    height: 50px;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background-color: #fff;
    border: 2px solid var(--company-identity-color);
    justify-content: center;
    min-width: 60px;

    span {
      align-items: center;
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
    }
  }
}
