.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    font-size: 16px;
}

.center-box {
    margin: auto;
}

button:not(:disabled) {
    cursor: pointer;
}

.alert-text {
    color: #f00;
}

hr {
    border-top: 0.5px solid #ccc;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

*:focus {
    outline: none;
}

*, *:after, *:before {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
  