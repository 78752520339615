.compare-toolbar {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}

.compare-toolbar-action-row {
  font-size: 20px;


  .btn-group {
    .button {
      min-height: 3em;
      width: 100%;
      margin-top: 1em;
    }

    .add-product-btn {
      min-width: 80px;
      border: 1px solid var(--company-identity-color);
      border-radius: 8px;
      background-color: white;
      color: var(--company-identity-color);
      font-weight: 700;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
    }

    .add-icon {
      height: 1.7em;
      width: 1.7em;
      filter: var(--svg-filter);

    }
  }
}

.product-select-group {
  .toolbar-header {
    font-weight: 500;
    line-height: 32px;
  }
}

.product-select-container {
  display: block;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  margin-top: 1.5em;

  .delete-product-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 200px;
    border: 1px solid red;
    background-color: white;
    color: red;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
    width: 1.7em;
    height: 1.7em;
  }
}


@media screen and (min-width: 600px) {
  .compare-toolbar-action-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn-group {
    display: flex;
    align-items: center;
    gap: 15px;
    align-self: flex-end;
  }
}

@media screen and (min-width:768px) {
  .product-select-group {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .product-select-container {
    flex: 1;
    width: fit-content;
    max-width: 320px;
    min-width: 180px;
  }
}