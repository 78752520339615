.dot-flashing {
    position: relative;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #535353;
    color: #535353;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}
.dot-flashing::before {
    left: -10px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #535353;
    color: #535353;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
}
.dot-flashing::after {
    left: 10px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #535353;
    color: #535353;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
}
  
@keyframes dot-flashing {
    0% {
        background-color: #535353;
    }
    50%, 100% {
        background-color: rgba(176, 176, 176, 0.2);
    }
}

@keyframes expand-button {
    0%, 10% {
      width: 60px;
    }
    100% {
      width: 120px;
    }
}

@keyframes collapse-button {
    0%, 10% {
      width: 120px;
      font-size: 16px;
    }
    100% {
      width: 60px;
      font-size: 40px;
    }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    0%, 10% {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes rotating {
    0%, 10% {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}

@keyframes expand {
    0%, 10% {
      width: 0%
    }
    100% {
      width: 100%
    }
}

@keyframes fade-in {
    0%, 10% {
      opacity: 0% 
    }
    100% {
      opacity: 100% 
    }
}

@keyframes colors {
    0% {
        background-color: rgb(239, 239, 239);
    }
    20% {
        background-color: yellow;
    }
    40% {
        background-color: red;
    }
    60% {
        background-color: green;
    }
    80% {
        background-color: blue;
    }
    100% {
        background-color: rgb(239, 239, 239);
    }
}