@use "./animation.scss";

$banner-animation-duration: 2s;

.container {
  background-color: var(--background-color);
  height: 100%;
}

.section-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}

.expand {
  -webkit-animation: expand $banner-animation-duration;
  -moz-animation: expand $banner-animation-duration;
  -ms-animation: expand $banner-animation-duration;
  -o-animation: expand $banner-animation-duration;
  animation: expand $banner-animation-duration;
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}

.fade-in {
  -webkit-animation: fade-in $banner-animation-duration linear;
  -moz-animation: fade-in $banner-animation-duration linear;
  -ms-animation: fade-in $banner-animation-duration linear;
  -o-animation: fade-in $banner-animation-duration linear;
  animation: fade-in $banner-animation-duration linear;
}