.compare-table {
  display: grid;
  align-items: center;
  background-color: white;
  padding: 12px 24px;
  margin: 24px 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
  overflow: auto;
  border-radius: 8px;


  .column-header {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
  }

  .row-header {
    font-weight: 600;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    height: 100%;
    min-height: 80px;
    border-bottom: 1px solid #efefef;
    white-space: pre-line;

    >img {
      width: 40px;
      filter: var(--svg-filter);
    }
  }

  .filter-box-item {
    padding: 20px 0px;
    display: flex;
    justify-content: flex-start;
  }

  .last-row {
    border-bottom: 0;
  }

  .product-link {
    text-align: center;
  }
}