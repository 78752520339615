.waiting-page-container {
  font-family: Arial, sans-serif;
  background-color: #f2f2f2;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;

  .waiting-container {
    text-align: center;
    background-color: #fff;
    padding: 50px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
  
    .top-image {
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      width: 20%;
      margin-top: 60px;
    }
    .people-icon {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: 40px;
    }
    .person {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 10px;
      position: relative;
    }
    .head {
      width: 20px;
      height: 20px;
      background-color: #f2cd79d0;
      border-radius: 50%;
    }
    .body {
      width: 30px;
      height: 40px;
      background-color: #33d2ca;
      clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
      margin-top: 5px; /* Adjust to connect the head and body */
    }
    h1 {
      margin: 20px 0 0;
      font-size: 24px;
      color: #333;
    }
    p {
      margin: 10px 0 0;
      color: #666;
    }
  }
  
}

