$nwlife-color: #009188;

.form-field {
  display: block;
  margin-bottom: 0.5em;
  font-weight: 600;
}

.upload-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}

.input-form {
  padding: 2em;
  line-height: 1.6;

  .input-box-container {
    display: block;
    margin-top: 1rem;
    
    input {
      background: #f9fafb;
      box-sizing: border-box;
      border: 1px solid #c0c4c9;
      border-radius: 0.25rem;
      font-size: 18px;
      width: 100%;
      padding: 10px;
    }

    input:focus{
      border-color: var(--company-identity-color);
    }

    input::placeholder {
      color: #929292;
    }
  }

  .upload-box-container {
    margin-top: 1rem;
    display: block;

    input {
      background: #f9fafb;
      font-size: 18px;
      box-sizing: border-box;
      border: 1px dashed #c0c4c9;
      border-radius: 0.25rem;
      width: 100%;
      padding: 20px;
    }

    input::file-selector-button {
      cursor: pointer;
      font-weight: bold;
      background: var(--company-identity-color);
      color: white;
      border: thin solid grey;
      border-radius: 0.5rem;
      padding: 0.5em;
      margin-right: 1rem;
    }
  }

  input[type=submit]{
    background: #f9fafb;
    color: var(--company-identity-color);
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    border: 1px solid var(--company-identity-color);
    border-radius: 0.5rem;
    padding: 0.7em;
    margin-top: 1.5rem;

    &:disabled{
      border: 1px solid #c0c4c9;
      color: #c0c4c9;
    }
  }
}
