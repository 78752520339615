$loading-animation-duration: 1s;

.export-btn {
  min-width: 80px;
  border-radius: 8px;
  color: var(--company-identity-color);
  font-weight: 700;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
  width: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--company-identity-color);
  background-color: var(--company-identity-color);
}

.download-icon,
.loading-icon {
  height: 1.7em;
  width: 1.7em;
}

.loading-icon {
  -webkit-animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
  -moz-animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
  -ms-animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
  -o-animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
  animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
}

@keyframes rotating-no-delay {
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading {
  cursor: default;
}
